<template>
  <content justify="center">
    <!-- DIALOGO RESPUESTA RETORNO PEDIDOS -->
    <v-dialog
      persistent
      v-model="dialogoRespuestaRetorno"
      max-width="700"
      scrollable
    >
      <v-card>
        <v-card-title class="info lighten-1 white--text">
          <strong>RESULTADO</strong>
          <v-progress-circular
            class="ml-2"
            indeterminate
            :color="colorProgreso"
          ></v-progress-circular>
        </v-card-title>

        <v-card-text style="height: 400px">
          <v-row>
            <v-col>
              <v-simple-table dense fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Pedido</th>
                      <th class="text-left">Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in resultadoRetornos" :key="item.orderId">
                      <td>{{ item.orderId }}</td>
                      <td>{{ item.observacion }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            class="mt-2"
            color="info"
            @click="dialogoRespuestaRetorno = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- FIN DIALOGO RESPUESTA RETORNO PEDIDOS -->

    <v-form ref="enviarFormulario" v-model="validar">
      <v-row class="d-flex align-center justify-center">
        <v-col cols="12" sm="8" md="7" lg="7" xl="7">
          <v-row class="justify-center">
            <v-col sm="8" md="7" lg="7">
              <h3>
                <v-icon size="50" color="info"
                  >mdi-arrow-u-left-bottom-bold</v-icon
                >
                Retorno de pedidos
              </h3>
            </v-col>
          </v-row>

          <v-row class="justify-center">
            <v-col sm="8" md="7" lg="7">
              <v-autocomplete
                :items="listaRayos"
                item-value="id"
                v-model="rayoSeleccionado"
                item-text="text"
                label="Selecciona Rayo"
                solo
                :rules="rayoRules"
                :loading="cargandoListaRayos"
                required
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" sm="8" md="5" lg="5">
          <v-textarea
            ref="buscador"
            v-model="listaPedidos"
            outlined
            name="input-7-4"
            label=""
            value=""
            required
            :rules="pedidosRules"
          ></v-textarea>
          <v-row justify="center">
            <v-btn
              @click="retornarPedidos"
              color="success"
              :disabled="!validar"
            >
              Ingresar
            </v-btn>
            <v-btn class="ml-4" color="warning" @click="limpiarDatos">
              Limpiar datos
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </content>
</template>

<script>
import axios from "axios";
import firebase from "firebase";

export default {
  data() {
    return {
      rayoRules: [(v) => !!v || "Debes seleccionar Rayo"],
      pedidosRules: [(v) => !!v || "Debes ingresar al menos un pedido"],
      listaRayos: [],
      rayoSeleccionado: "",
      listaPedidos: "",
      dialogoRespuestaRetorno: false,
      colorProgreso: "",
      validar: false,
      cargandoListaRayos: false,
      fechaActual: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      itemsFallidos: [],
      resultadoRetornos: [],
    };
  },
  computed: {
    async obtenerRayos() {
      try {
        console.log("Obteniendo Rayos...");
        this.cargandoListaRayos = true;
        let arrayFleets = [];
        const listaRayos = await axios.get(
          "https://backendservices.rayoapp.com/Rayos/rayos/reduced",
          {
            headers: {
              "warehouse.rayoapp.com": "rayo",
            },
          }
        );

        for (const n in listaRayos.data) {
          arrayFleets.push({
            text: listaRayos.data[n].name_rayo,
            id: listaRayos.data[n].id,
          });
        }

        console.log(listaRayos.status);

        listaRayos.status == 200
          ? console.log("Lista creada!")
          : console.log("No se logró obtener la lista! Revisar API");

        arrayFleets.sort((b, a) => b.text.localeCompare(a.text));
        this.listaRayos = arrayFleets;

        this.cargandoListaRayos = false;
      } catch (error) {
        this.cargandoListaRayos = false;
        console.log(error);
      }
    },
  },

  methods: {
    datosUsuario () {
      // console.log(firebase.auth())
      return {
        email_user: firebase.auth().currentUser.email,
        name_user: firebase.auth().currentUser.displayName,
        uid: firebase.auth().currentUser.uid
      }
    },
    limpiarDatos() {
      this.$refs.enviarFormulario.reset();
    },
    validarFormulario() {
      this.$refs.enviarFormulario.validate();
    },
    async consultarPedido(pedido) {
      console.log("Buscando code task nueva tarea...");
      let consultaPedido = await axios.get(
        `https://backendservices.rayoapp.com/Task-Groups/tasks_group/aux/by?idOrder=${pedido}`,
        {
          headers: {
            "warehouse.rayoapp.com": "rayo",
          },
        }
      );

      console.log(consultaPedido);

      let data = consultaPedido.data[0];
      let code_task = data.tasks[data.tasks.length - 1].code_task.toString();

      return code_task;
    },
    async retornarPedidos() {
      try {
        this.colorProgreso = "white";
        this.dialogoRespuestaRetorno = true;
        this.resultadoRetornos = [];
        var arreglo_pedidos = this.listaPedidos.split(/\r\n|\n|\r/);
        var estado_name = "Returned";
        var estado_id = "609a4b48548311cec3b7f711";
        var posicion_name = "Revision";
        var posicion_id = "61df3cd7d75becc9a2781625";
        var rayo = "";
        var email = this.datosUsuario().email_user;
        var rayo_id = this.rayoSeleccionado;

        var nuevoArreglo = [];

        for (const i in this.listaRayos) {
          if (this.rayoSeleccionado == this.listaRayos[i].id) {
            rayo = this.listaRayos[i].text;
          }
        }

        console.log("CANT DE PEDIDOS: " + arreglo_pedidos.length);

        for (var j = 0; j < arreglo_pedidos.length; j++) {
          if (arreglo_pedidos[j] != "") {
            let code_task = await this.consultarPedido(
              arreglo_pedidos[j].trim()
            );
            var formData = {
              codigo: arreglo_pedidos[j].trim(),
              estado: estado_id,
              estado_name: estado_name,
              posicion_name: posicion_name,
              posicion: posicion_id,
              code_task: code_task,
              email: email,
              rayo: rayo,
              _id: rayo_id,              
              emailResponsable: this.datosUsuario().email_user,
          nameResponsable: this.datosUsuario().name_user,
          uidResponsable: this.datosUsuario().uid,
          sistemaResponsable: "warehouse.rayoapp.com",
          epochResponsable: new Date().getTime()
            };

            nuevoArreglo.push(formData);
          }
        }

        console.log("LO QUE ENVÍO: ", nuevoArreglo);

        console.log("Enviando retornos...");

        var respuestaPeticion = await axios.post(
          "https://data.mongodb-api.com/app/warehouse-rayo-imrsd/endpoint/retornar",

          nuevoArreglo
        );
        console.log(respuestaPeticion.status);
        console.log("LO QUE RECIBO: ", respuestaPeticion.data);

        for (const l in respuestaPeticion.data) {
          var order_id = respuestaPeticion.data[l].order_id;
          var observacion = respuestaPeticion.data[l].result;

          let resultado = {
            orderId: `${order_id}`,
            observacion: `${observacion}`,
          };
          this.resultadoRetornos.push(resultado);
        }

        this.colorProgreso = "info lighten-1";
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.obtenerRayos;
    this.datosUsuario();
  },
};
</script>
